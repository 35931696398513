<template>
  <div>
    <!-- HEADING -->
    <section class="py-5">
      <div class="mx-auto col-lg-8">
        <h2 class="font-size-large highlighted-color1 mb-4">
          <span>Procure por um tema ou palavra chave</span>
        </h2>
        <form @submit.prevent="search" class="default-form d-flex align-items-lg-center">
          <div class="col-lg-10 me-2">
            <input type="text" class="form-control" v-model="form.term" placeholder="O que você deseja aprender hoje?" />
          </div>
          <div>
            <Button type="submit" class="btn-primary">
              Buscar
            </Button>
          </div>
        </form>
      </div>
    </section><!-- END HEADING -->
    <!-- HIGHLIGHT -->
    <template v-if="!isSearch">
      <template v-if="homeHighlightLoader">
        <Loader
          :size="'big'"
        />
      </template>
      <template v-else>
        <section id="highlight" class="py-5 mt-5 d-flex flex-column flex-lg-row px-5">
          <div class="col-lg-8 pe-5">
            <h3 class="font-size-regular highlighted-color1 mb-3">
              <span>Comece por aqui</span>
            </h3>
            <ul>
              <li v-for="(item, index) in startList" :key="index" class="mb-3">
                <router-link :to="{ name: 'help-center.entry.show', params: { slug: item.fields.slug } }" class="link-color2">
                  {{ item.fields.title }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-lg-4">
            <h3 class="font-size-regular highlighted-color1 mb-3">
              <span>{{ highlightContentTitle }}</span>
            </h3>
            <div>
              <HelpCenterEntryCard
                :entry="highlightContentEntry"
              />
            </div>
          </div>
        </section>
      </template>
    </template>
    <!-- END HIGHLIGHT -->
    <!-- LIST -->
    <template v-if="entriesLoader">
      <Loader
        :size="'big'"
      />
    </template>
    <template v-else>
      <div class="mt-5">
        <HelpCenterEntryList
          :entries="entryList"
        />
      </div>
      <div class="mt-4 text-center" v-if="isSearch">
        <router-link :to="{ name: 'help-center.index' }" class="btn btn-primary">
          Voltar
        </router-link>
      </div>
    </template><!-- END LIST -->
  </div>
</template>

<script>

import HelpCenterServices from '@/modules/help-center/services/help-center-services'

import Button from '@/components/common/Button/Button'
import HelpCenterEntryCard from '@/components/help-center/HelpCenterEntryCard'
import HelpCenterEntryList from '@/components/help-center/HelpCenterEntryList'
import Loader from '@/components/common/Loader/Loader'

export default {
  components: {
    Button,
    HelpCenterEntryCard,
    HelpCenterEntryList,
    Loader
  },
  data () {
    return {
      data: null,
      entriesLoader: false,
      homeHighlightData: null,
      homeHighlightLoader: false,
      form: {
        term: ''
      }
    }
  },
  metaInfo: {
    title: 'Dúvidas e Apoio'
  },
  created () {
    if (!this.isSearch) this.getHomeHighlight()
    if (this.$route.query.term) this.form.term = this.$route.query.term
    this.getEntries()
  },
  watch: {
    $route (to, from) {
      this.reset()
      this.getEntries()
      if (to.name === 'help-center.search') {

      } else {
        this.getHomeHighlight()
      }
    }
  },
  computed: {
    /**
     * Entry list
     */
    entryList () {
      if (!this.data || this.data.items.length === 0) return []
      const entries = JSON.parse(JSON.stringify(this.data.items))
      // Get associated assets (cover and thumbnail)
      entries.forEach(entry => {
        if (entry.fields.cover) {
          entry.fields.cover = this.data.includes.Asset.find(item => {
            return item.sys.id === entry.fields.cover.sys.id
          })
        }
        if (entry.fields.thumbnail) {
          entry.fields.thumbnail = this.data.includes.Asset.find(item => {
            return item.sys.id === entry.fields.thumbnail.sys.id
          })
        }
      })

      return entries
    },
    /**
     * Highlight entry
     */
    highlightContentEntry () {
      if (!this.homeHighlightData || this.homeHighlightData.items.length === 0) return null
      const entry = this.homeHighlightData.includes.Entry.find(item => {
        return item.sys.id === this.homeHighlightData.items[0].fields.highlightContent.sys.id
      })
      // Get associated assets (cover and thumbnail)
      if (entry.fields.cover) {
        entry.fields.cover = this.homeHighlightData.includes.Asset.find(item => {
          return item.sys.id === entry.fields.cover.sys.id
        })
      }
      if (entry.fields.thumbnail) {
        entry.fields.thumbnail = this.homeHighlightData.includes.Asset.find(item => {
          return item.sys.id === entry.fields.thumbnail.sys.id
        })
      }
      return entry
    },
    /**
     * Highlight title
     */
    highlightContentTitle () {
      if (!this.homeHighlightData || this.homeHighlightData.items.length === 0) return ''
      return this.homeHighlightData.items[0].fields.highlightContentTitle
    },
    /**
     * Is search page
     */
    isSearch () {
      return this.$route.name === 'help-center.search'
    },
    /**
     * Highlight start from here list
     */
    startList () {
      if (!this.homeHighlightData || this.homeHighlightData.items.length === 0) return []
      const ids = []
      this.homeHighlightData.items[0].fields.startHereList.forEach(item => {
        ids.push(item.sys.id)
      })
      return this.homeHighlightData.includes.Entry.filter(item => {
        return ids.includes(item.sys.id)
      })
    }
  },
  methods: {
    /**
     * Get help center entries from API
     */
    async getEntries () {
      this.entriesLoader = true
      try {
        let data = null
        if (this.form.term) data = await HelpCenterServices.searchEntriesByTerm(this.form.term)
        else data = await HelpCenterServices.getEntries()
        this.data = data
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.entriesLoader = false
    },
    /**
     * Get highlighted content from home
     */
    async getHomeHighlight () {
      this.homeHighlightLoader = true
      try {
        const data = await HelpCenterServices.getHomeHighlight()
        this.homeHighlightData = data
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.homeHighlightLoader = false
    },
    /**
     * Reset page
     */
    reset () {
      this.data = null
      this.homeHighlightData = null
      if (this.$route.query.term) this.form.term = this.$route.query.term
      else this.form.term = ''
    },
    /**
     * Perform search
     */
    async search () {
      this.$router.push({ name: 'help-center.search', query: { term: this.form.term } })
    }
  }
}
</script>

<style lang="scss" scoped>
  #highlight {
    border-top: 2px solid #CFD1E4;
    border-bottom: 2px solid #CFD1E4;
  }
</style>
